import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import ImgBase from 'gatsby-image/withIEPolyfill';
import { color, mixin, breakpoint, size } from "@src/settings";

export const Container = styled.div`
	background-color: #FFF;
	margin: 9.3rem 0 11.7rem;
	padding: 5rem;
	${ mixin.baseWidthSet }
	${ media.lessThan(`${ breakpoint.tb }px`)`
		padding: 2.5rem;
	`}
	${ media.lessThan(`${ breakpoint.tb }px`)`
		margin-top: ${ size.sectionGap_sp }px;
		margin-bottom: ${ size.sectionGap_sp }px;
	`}
`

export const Cont = styled.div`
	display:flex;
	justify-content:space-between;
	margin: auto;
	${ media.lessThan(`${ breakpoint.tb }px`)`
		flex-direction: column-reverse;
	`}
`

export const Column = styled.div`
	flex: auto 1 1;
	& > h2{
		color: ${color.blue};
		font-size: 2.1rem;

	}
	& > p{
		margin-top: 2.5rem;
		font-size: 1.6rem;
		line-height: 2;
		color: ${color.txtLight};
		width: 100%;
	}
`

export const Prof = styled.div`
	flex: 20rem 0 0;
	margin-right: 3rem;
	& > div {
		margin-top: 1.6rem;
		& > h3{
			font-size: 1.4rem;
			line-height: 1.9rem;
			color: #6E7D91;
		}
		& > p{
			font-size: 1.6rem;
			color: ${color.blue};
			line-height: 2.1rem;
			margin-top: 0.2rem;
		}
	}
	${ media.lessThan(`${ breakpoint.tb }px`)`
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0;
		margin-top: 2rem;
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
	`}
`

export const Img = styled(ImgBase)`
	width: 100%;
	${ media.lessThan(`${ breakpoint.tb }px`)`
		flex: 13rem 0 0;
		margin-right: 3rem;
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-right: 0;
		margin-bottom: 2rem;
	`}
`
