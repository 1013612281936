import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery} from 'gatsby';

import Hero from '@components/common/hero';
import ContactIntro from '@components/common/contact-intro';

import Message_hero__mds from '@img/svg/message/hero__mds.svg';
import Layout from '@components/common/layout';
import { Container, Cont, Column, Prof, Img } from '@components/message/styles';
import { Tab, TabItem } from '@components/common/styles';
import { color, mixin } from '@src/settings';

const Message_page = ({ data }) => (
	<Layout baseBgColor={ color.paleGray }>
		<Hero
			img={ data["Message_hero_img"] }
			title={<Message_hero__mds />}
			subTitle="代表挨拶"
		/>
		<Tab>
			<TabItem to="/message" className="isActive"><span>Message</span><span>代表挨拶</span></TabItem>
			<TabItem to="/company"><span>Company</span><span>会社概要・沿革</span></TabItem>
		</Tab>
		<Container>
			<Cont>
				<Prof>
					<Img fluid={ data["president"].childImageSharp.fluid } fade={ true } />

					<div>
						<h2>Managing Director</h2>
						<p>Dato’ Stan Lee</p>
					</div>

				</Prof>
				<Column>
					<h2>モノづくりは人づくり、人づくりは心づくり</h2>

					<p>2000年創立以来、 我々は創業時の謙虚さを常々振り返るとともに、あらゆる変化に対し前向きな姿勢で取り組むモノづくりにこだわり努めてまいりました。<br/>時代や環境がどのように変化しようとも変わらない我々の信条、それは人財にあります。LSF独自のモノづくり力を支える人財の育成、つまり人づくりは我々にとって欠かせないものとなっています。</p>

					<p>企業としての更なる成長のため、技術力やマネジメント力を向上していく一方で、<br/>LSFの企業キーワードでもある誠意や尊敬、熱意といった人づくりの基本となる心づくりはLSFの企業価値を高める上で大変重要な取り組みとなっています。</p>

					<p>高品質の製品の追求には我々の熱意が原点であり、その熱意こそが更なる品質向上へと繋がり、その最終的な結果としてお客さまにご満足していただくことができ、厚い信頼が得られるものと考えております。<br/>今後もモノづくりを支える人づくり、心づくりのための人財育成に注力し、継続的に精励努力してまいります。引き続きご支援を賜りますようお願い申し上げます。</p>

				</Column>
			</Cont>
		</Container>
		<ContactIntro />
	</Layout>
)


export const query= graphql`
	query messageImgs{

		Message_hero_img: file( relativePath: { eq: "message/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		president: file( relativePath: { eq: "message/president.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`

export default Message_page

